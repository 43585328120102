import React, { useState, useEffect } from "react";
import { Button } from "../../components/ui/button";
import { TimePickerDialog } from "./TimePickerDialog";
import VehicleSelector from "./VehicleSelect";
import { useGetScheduleDataQuery } from "../../api/busLineOfficeApi";
import { errorHandler } from "../../error/index";
import { useOfficeUserAuth } from "../../api/officeAuth";
import { Loader2 } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { setRefetchSchedulePage } from "../../slices/schedule";
import { RootState } from "../../slices/types";
import ScheduleCard from "./ScheduleCard";
import { setRefetch } from "../../slices/addVehicleToSchedule";

interface ScheduleData {
  scheduleId: string;
  time: string;
  routeNames: string[];
  vehicleCount: number;
  totalCapacity: number;
  totalBookedSeats: number;
  availableSeats: number;
}

interface VehicleData {
  id: string;
  registrationNumber: string;
  model: string;
  capacity: number;
}

export default function VehicleDeparture() {
  const { officeId } = useOfficeUserAuth();
  const { refetch } = useSelector((state: RootState) => state.refetch);
  const dispatch = useDispatch();
  const { refetchSchedulePage } = useSelector(
    (state: RootState) => state.refetchSchedulePage
  );
  const {
    isLoading,
    isError,
    data,
    error,
    refetch: dataRefetch,
  } = useGetScheduleDataQuery(officeId);
  const [time, setTime] = useState<string>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isVehicleSelectDialogOpen, setVehicleSelectDialogOpen] =
    useState<boolean>(false);

  const [schedule, setScheduleData] = useState<ScheduleData[]>([]);

  useEffect(() => {
    if (data?.schedules) {
      setScheduleData(data.schedules);
    }
  }, [data]);

  const handleChange = (value) => {};

  useEffect(() => {
    (async () => {
      if (refetchSchedulePage) {
        dataRefetch();
      }
      dispatch(setRefetchSchedulePage({ refetchSchedulePage: false }));
    })();
  }, [dataRefetch, dispatch, refetchSchedulePage]);

  useEffect(() => {
    (async () => {
      if (refetch) {
        dataRefetch();
      }
      dispatch(setRefetch({ refetch: false }));
    })();
  }, [dataRefetch, dispatch, refetch]);

  useEffect(() => {
    if (isError && error) {
      errorHandler(error);
    }
  }, [error, isError]);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div>
        <div className="flex justify-start">
          <Button
            variant="outline"
            className="text-blue-600 border-blue-600 border-2 w-32 mr-10"
          >
            Schedule
          </Button>
          <Button
            variant="outline"
            className="text-blue-600 border-blue-600 border-2 w-32"
            onClick={() => setDialogOpen(true)}
          >
            Add Time
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-20 mt-10">
        {schedule.length > 0 ? (
          schedule.map((singleCard, index) => (
            <ScheduleCard
              key={index}
              scheduleData={singleCard}
              vehicleData={data.availableVehicles as VehicleData[]}
              routeNames={singleCard.routeNames}
              officeId={officeId}
            />
          ))
        ) : (
          <div className="flex justify-center items-center h-72">
            <p className="text-gray-500">No schedules available</p>
          </div>
        )}
      </div>

      {isVehicleSelectDialogOpen && (
        <VehicleSelector
          onSelectionChange={handleChange}
          isDialogOpen={isVehicleSelectDialogOpen}
          setDialogOpen={setVehicleSelectDialogOpen}
        />
      )}

      {dialogOpen && (
        <TimePickerDialog
          isOpen={dialogOpen}
          setOpen={setDialogOpen}
          setSelectedTime={setTime}
        />
      )}
    </>
  );
}

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen">
    <Loader2 className="h-8 w-8 animate-spin" />
    <span className="ml-2">Loading...</span>
  </div>
);
