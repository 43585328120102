import React, { useState, useCallback, useEffect } from "react";
import { Button } from "../../components/ui/button";
import SalesPage from "./SalesPage";
import TripsAndSharesBusiness from "./TripsAndSharesBusiness";
import { useGetCompanyHomePageDataQuery } from "../../api/busLineApiData";
import { useAuth } from "../../api/getCredentials";
import { DatePickerDemo } from "../../components/ui/DatePickerDemo";
import SelectComponent from "./Re-Usables/SelectComponent";

export default function NewBusinessHome() {
  const { token, companyId } = useAuth();
  const [currentView, setCurrentView] = useState<string>("sales");
  const [dateChosen, setDate] = useState<Date | undefined>();
  const [period, setSelectedValue] = useState<string>("");
  const date = formatDate(dateChosen);
  const { refetch } = useGetCompanyHomePageDataQuery(
    { companyId, date },
    {
      skip: !token || !companyId,
    }
  );

  useEffect(() => {
    if (date && companyId) {
      refetch();
    }
  }, [companyId, date, refetch]);

  const handleDateChange = useCallback((newDate: Date | undefined) => {
    setDate(newDate);
    setSelectedValue("");
  }, []);

  const handlePeriodChange = useCallback((newPeriod: string | undefined) => {
    setSelectedValue(newPeriod);
    setDate(undefined);
  }, []);

  return (
    <div className="p-6 min-h-screen flex flex-col">
      <header className="flex justify-between mb-6 gap-4">
        <Button
          variant="outline"
          className={`px-4 py-2 text-sm ${
            currentView === "sales"
              ? "bg-customDarkBlue text-primary-foreground"
              : ""
          }`}
          onClick={() => setCurrentView("sales")}
        >
          Company Sales
        </Button>
        <Button
          variant="outline"
          className={`px-4 py-2 text-sm ${
            currentView === "trips&shares"
              ? "bg-customDarkBlue text-primary-foreground"
              : ""
          }`}
          onClick={() => {
            setCurrentView("trips&shares");
          }}
        >
          Trips & Shares
        </Button>

        <div className="flex justify-end items-center mt-3 space-x-4">
          <div>
            <DatePickerDemo date={dateChosen} setDate={handleDateChange} />
          </div>
          <SelectComponent
            value={period}
            onValueChange={
              (value: string) => handlePeriodChange(value)
              // setSelectedValue(value)
            }
          />
        </div>
      </header>
      {currentView === "sales" && <SalesPage date={date} period={period} />}
      {currentView === "trips&shares" && (
        <TripsAndSharesBusiness date={date} period={period} />
      )}
    </div>
  );
}

function formatDate(date: Date | undefined): string {
  return date
    ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`
    : "";
}
