import React, { useEffect, useState, useCallback } from "react";
import { OfficeReportTicketsData, columns } from "./office_ticket_table_column";
import { DataTable } from "../../business_components/Re-Usables/data-table";
import { errorHandler } from "../../../error/index";
import { Loader2 } from "lucide-react";
import { useOfficeUserAuth } from "../../../api/officeAuth";
import { useGetOfficeRevenueAndDataQuery } from "../../../api/busLineOfficeApi";
import { Button } from "../../../components/ui/button";
import { DatePickerOfficeDemo } from "../../../components/ui/DatePickerOfficeDemo";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../slices/types";
import { setRefetchTicketsPage } from "../../../slices/refetchSlice";

export default function OfficeTicketsTable({ openDialog }) {
  const dispatch = useDispatch();
  const { officeId } = useOfficeUserAuth();
  const { refetchTicketsPage } = useSelector(
    (state: RootState) => state.refetchTicketsPage
  );
  const [pageIndex, setPageIndex] = useState(0);
  const [date, setDate] = React.useState<Date | undefined>();
  const formattedDate = date
    ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`
    : "";
  const handleDateChange = useCallback((newDate: Date | undefined) => {
    setDate(newDate);
  }, []);
  const {
    isLoading: getSoldTicketsLoading,
    data: ticketData,
    error: getTicketsError,
    isError: isGetTicketError,
    refetch,
  } = useGetOfficeRevenueAndDataQuery(
    { officeId, page: pageIndex + 1, formattedDate },
    { skip: !officeId }
  );
  console.log("In tickets Page", ticketData);

  useEffect(() => {
    if (isGetTicketError) {
      errorHandler(getTicketsError);
    }
  }, [getTicketsError, isGetTicketError]);

  useEffect(() => {
    (async () => {
      if (refetchTicketsPage) {
        await refetch();
        dispatch(setRefetchTicketsPage({ refetchTicketsPage: false }));
      }
    })();
  }, [dispatch, refetch, refetchTicketsPage]);

  const prepareTableData = (): OfficeReportTicketsData[] => {
    if (!ticketData || !ticketData.ticketData) {
      return [];
    }

    return ticketData.ticketData.tickets.map((ticket) => ({
      ticketNumber: ticket.ticketNumber,
      userName: `${ticket.user.firstName} ${ticket.user.lastName}`,
      userPhoneNumber: ticket.user.phoneNumber,
      from: ticket.route.startPoint,
      to: ticket.route.endPoint,
      price: ticket.price,
      bookingOffice: ticketData.ticketData.name,
      purchasedDate: ticket.purchaseDate,
    }));
  };

  if (getSoldTicketsLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  const tableData = prepareTableData();

  return (
    <div className="container mx-auto py-10">
      <div className="flex items-center space-x-4 mt-4">
        <Button
          variant="outline"
          className="bg-[#EEF1FE] px-4 py-2 text-sm"
          onClick={() => openDialog("ticket")}
        >
          New Ticket
        </Button>

        <DatePickerOfficeDemo date={date} setDate={handleDateChange} />
      </div>

      <div className="bg-[#7485BF] text-white text-center p-4 text-xl sm:text-2xl md:text-3xl font-semibold mb-6 mt-5">
        {ticketData?.officeAmount && (
          <>
            Total Page Sales: Ksh{" "}
            {ticketData?.officeAmount?.toFixed(2) ?? "0.00"}
          </>
        )}
      </div>

      <DataTable
        columns={columns}
        data={tableData}
        pageCount={ticketData?.totalPages}
        pageIndex={pageIndex}
        onPaginationChange={setPageIndex}
      />
    </div>
  );
}
