import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Checkbox } from "../../../components/ui/checkbox";


export type ScheduleListItems = {
  vehicle: string;
  driver: string;
  departureTime: string;
  route: string;
  occupiedSeats: string;
  dispatchVehicle: string;
};


export const createColumns = (): ColumnDef<ScheduleListItems>[] => [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected()
            ? true
            : table.getIsSomePageRowsSelected()
            ? "indeterminate"
            : false
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "vehicle",
    header: "Vehicle",
  },
  {
    accessorKey: "driver",
    header: "Driver",
  },
  {
    accessorKey: "departureTime",
    header: "Departure Time",
  },
  {
    accessorKey: "route",
    header: "Route",
  },
  {
    accessorKey: "occupiedSeats",
    header: "Occupied Seats",
  },
  {
    accessorKey: "dispatchVehicle",
    header: "Dispatch Vehicle",
  },
];
