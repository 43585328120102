import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from "../../components/ui/dialog";
import { TimePicker } from "./TimePicker";
import { Button } from "../../components/ui/button";
import { Waypoints, BusFrontIcon } from "lucide-react";
import VehicleSelector from "./VehicleSelect";
import RouteSelector from "./RouteSelect";
import { useCreateNewScheduleMutation } from "../../api/busLineOfficeApi";
import { useOfficeUserAuth } from "../../api/officeAuth";
import { successFunctionDisplay } from "../../success/success";
import { errorHandler } from "../../error/index";
import { useDispatch } from "react-redux";
import { setRefetchSchedulePage } from "../../slices/schedule";
import { setUpdateVehicleData } from "../../slices/addTimeRefetch";

interface TimePickerProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  setSelectedTime: (value: string) => void;
  // addCard: () => void;
}

interface RouteData {
  id: string;
  name: string;
}

interface VehicleData {
  id: string;
  model: string;
  registrationNumber: string;
}

const TimePickerDialog = ({
  isOpen,
  setOpen,
  setSelectedTime,
}: // addCard,
TimePickerProps) => {
  const dispatch = useDispatch();
  const { officeId } = useOfficeUserAuth();
  const [time, setSelected] = useState<string>();
  const [route, setRoute] = useState<RouteData[]>([]);
  const [vehicle, setVehicle] = useState<VehicleData[]>([]);
  const handleTimeChange = (time: string) => {
    setSelectedTime(time);
    setSelected(time);
  };
  const [postData] = useCreateNewScheduleMutation();

  const [isVehicleSelectDialogOpen, setVehicleSelectDialogOpen] =
    useState<boolean>(false);

  const [isRouteSelectDialogOpen, setRouteSelectDialogOpen] =
    useState<boolean>(false);

  const handleConfirm = async () => {
    setOpen(false);
    const result = await postData({
      time,
      officeId,
      routeId: route.map((r) => r.id),
      vehicleIds: vehicle.map((v) => v.id),
    });
    if ("data" in result) {
      successFunctionDisplay("Schedule Added Successfully");
      dispatch(setRefetchSchedulePage({ refetchSchedulePage: true }));
      dispatch(setUpdateVehicleData({ updateVehicleDataRefetch: true }));
    } else {
      return errorHandler(result.error);
    }
  };
  const handleChange = (value: VehicleData[]) => {
    setVehicle(value);
  };

  const handleRouteChange = (value: RouteData[]) => {
    setRoute(value);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-green-900">New Schedule</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <TimePicker onChange={handleTimeChange} />
          </div>
          <div className="flex space-x-6 items-center">
            <div
              className="flex flex-col items-center"
              onClick={() => setRouteSelectDialogOpen(true)}
            >
              <div className="w-20 h-20 bg-green-900 flex items-center justify-center rounded-md">
                <Waypoints size={32} color="white" />
              </div>
              <p className="mt-2 text-green-900 font-semibold">Add Route</p>
            </div>
            <div
              className="flex flex-col items-center"
              onClick={() => setVehicleSelectDialogOpen(true)}
            >
              <div className="w-20 h-20 bg-green-900 flex items-center justify-center rounded-md">
                <BusFrontIcon size={32} color="white" />
              </div>
              <p className="mt-2 text-green-900 font-semibold">Add Vehicle</p>
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>
            <Button
              onClick={handleConfirm}
              className="bg-green-900"
              // variant="outline"
            >
              Save Changes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {isVehicleSelectDialogOpen && (
        <VehicleSelector
          onSelectionChange={handleChange}
          isDialogOpen={isVehicleSelectDialogOpen}
          setDialogOpen={setVehicleSelectDialogOpen}
        />
      )}

      {isRouteSelectDialogOpen && (
        <RouteSelector
          isDialogOpen={isRouteSelectDialogOpen}
          setDialogOpen={setRouteSelectDialogOpen}
          onSelectionChange={handleRouteChange}
        />
      )}
    </>
  );
};

export { TimePickerDialog };
