import React, { useEffect, useState } from "react";
import { CompanyFleetData, columns } from "./company_fleet_column";
import { DataTable } from "../Re-Usables/data-table";
import { useAuth } from "../../../api/getCredentials";
import { useGetCompanyFleetQuery } from "../../../api/busLineApiData";
import { errorHandler } from "../../../error/index";
import { Loader2 } from "lucide-react";

export default function CompanyFleetTableData() {
  const { companyId, token } = useAuth();
  const [pageIndex, setPageIndex] = useState(0);
  const {
    isLoading,
    isError,
    data: fleet,
    error,
  } = useGetCompanyFleetQuery(
    { companyId, page: pageIndex + 1 },
    {
      skip: !token || !companyId,
    }
  );

  useEffect(() => {
    if (isError) {
      errorHandler(error);
    }
  }, [error, isError]);

  const prepareTableData = (): CompanyFleetData[] => {
    if (
      !fleet ||
      !fleet.fleet ||
      !fleet.fleet.vehicles ||
      !fleet.fleet.vehicles.length
    ) {
      return [];
    }

    return fleet.fleet.vehicles.map((vehicle) => ({
      vehicleId: vehicle.id,
      vehicle: vehicle.registrationNumber,
      owner: vehicle.owner.name,
      phoneNumber: vehicle.owner.phone,
      model: vehicle.model,
      status: vehicle.status,
    }));
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  const tableData = prepareTableData();

  return (
    <div className="container mx-auto py-10 ">
      <DataTable
        columns={columns}
        data={tableData}
        pageCount={fleet?.totalPages ?? 1}
        pageIndex={pageIndex}
        onPaginationChange={setPageIndex}
      />
    </div>
  );
}
