import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../slices/types";
import { BASE_URL, LOCAL_BASEURL } from "../constants/constants";

export const busLineApi = createApi({
  reducerPath: "busLineApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    // baseUrl: LOCAL_BASEURL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const { token, companyId } = state.auth;
      if (token) {
        headers.set("token", token);
      }
      headers.set("Content-Type", "application/json");
      headers.set("companyId", companyId || "");
      return headers;
    },
  }),
  tagTypes: ["Vehicle"],
  endpoints: (builder) => ({
    postUserSignUp: builder.mutation({
      query: (signUpData) => ({
        url: "/create-company",
        method: "POST",
        body: signUpData,
      }),
    }),
    postUserLogin: builder.mutation({
      query: (userLoginData) => ({
        url: "/auth/companyAdminLogin",
        method: "POST",
        body: userLoginData,
      }),
    }),
    postCompanyAddVehicle: builder.mutation({
      query: ({ addVehicleData, companyId }) => ({
        url: "/add-vehicle",
        method: "POST",

        body: {
          companyId: companyId,
          driverEmail: addVehicleData.driverEmail,
          vehicles: [
            {
              registrationNumber: addVehicleData.vehicleRegistrationNumber,
              model: addVehicleData.vehicleModel,
              capacity: parseInt(addVehicleData.vehicleCapacity),
              ownerEmail: addVehicleData.ownerEmail,
              ownerName: addVehicleData.ownerName,
              ownerPhone: addVehicleData.ownerPhone,
            },
          ],
        },
      }),
      invalidatesTags: ["Vehicle"],
    }),
    getCompanyFleet: builder.query<any, any>({
      providesTags: ["Vehicle"],
      query: ({ companyId, page }) => ({
        url: `/get-all-company-fleet?companyId=${companyId}&page=${page}`,
        method: "GET",
      }),
    }),
    postCompanyAddParcel: builder.mutation({
      query: ({ parcelData, companyId }) => ({
        url: "/create-parcel",
        method: "POST",
        body: {
          companyId: companyId,
          senderName: parcelData.senderName,
          senderPhone: parcelData.senderPhoneNumber,
          recipientName: parcelData.recipientName,
          recipientPhone: parcelData.recipientPhone,
          fromLocation: parcelData.from,
          toLocation: parcelData.to,
          fees: parcelData.charges,
        },
      }),
    }),
    getCompanyParcels: builder.query<any, string>({
      query: (companyId) => ({
        url: `/get-All-Company-Parcels?companyId=${companyId}`,
        method: "GET",
      }),
    }),

    postTicketData: builder.mutation({
      query: ({ ticketData, vehicleRegistrationNumber, officeName }) => ({
        url: "/create-manual-ticket",
        method: "POST",
        body: {
          passengerName: ticketData.passengerName,
          officeName: officeName,
          phoneNumber: ticketData.phoneNumber,
          fromDestination: ticketData.from,
          toDestination: ticketData.to,
          fare: parseFloat(ticketData.fare),
          vehicleRegistrationNumber: vehicleRegistrationNumber,
        },
      }),
    }),
    getSoldTicketsAndData: builder.query<any, any>({
      query: ({ companyId, page }) => ({
        url: `/sold-tickets?companyId=${companyId}&page=${page}`,
        method: "GET",
      }),
    }),
    postCompanyAddOffice: builder.mutation({
      query: ({ officeData, companyId }) => ({
        url: "/add-company-office",
        method: "POST",
        body: {
          companyId: companyId,
          offices: [
            {
              name: officeData.name,
              address: officeData.address,
              city: officeData.city,
            },
          ],
        },
      }),
    }),
    getCompanyOffices: builder.query<any, any>({
      query: ({ companyId, page }) => ({
        url: `/get-company-offices?companyId=${companyId}&page=${page}`,
        method: "GET",
      }),
    }),
    postCompanyAddRoute: builder.mutation({
      query: ({ routeData, companyId }) => ({
        url: "/add-company-routes",
        method: "POST",
        body: {
          companyId: companyId,
          routes: [
            {
              name: routeData.routeName,
              startPoint: routeData.startPoint,
              endPoint: routeData.endPoint,
            },
          ],
        },
      }),
    }),
    getCompanyRoutesAndFare: builder.query<any, any>({
      query: ({ companyId, page }) => ({
        url: `/get-company-fare-routes?companyId=${companyId}&page=${page}`,
        method: "GET",
      }),
    }),
    postCompanyFareRates: builder.mutation({
      query: ({ fareData, companyId, routeId }) => ({
        url: "/add-fare-route",
        method: "POST",
        body: {
          companyId: companyId,
          fareRates: [
            {
              routeId: routeId,
              amount: parseInt(fareData.fareAmount),
            },
          ],
        },
      }),
    }),

    getCompanyFilteredTicketSales: builder.query({
      query: ({ credentials }) => ({
        url: `/filtered-total-company-sales?companyId=${credentials.companyId}&period=${credentials.period}&page=${credentials.page}`,
        method: "GET",
      }),
    }),
    postSpecificUserSignUp: builder.mutation({
      query: (userData) => ({
        url: "/auth/register",
        method: "POST",
        body: userData,
      }),
    }),
    postNormalUserLogin: builder.mutation({
      query: (userLoginData) => ({
        url: "/auth/login",
        method: "POST",
        body: userLoginData,
      }),
    }),
    generateOfficePassword: builder.query({
      query: ({ companyId, officeId }) => ({
        url: `/generate-office-password?companyId=${companyId}&officeId=${officeId}`,
        method: "GET",
      }),
    }),

    getCompanyAuditTotalIncome: builder.query({
      query: ({ companyId, startDate, endDate }) => ({
        url: `/get-company-audit?companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`,
        method: "GET",
      }),
    }),

    getCompanyAuditTotalExpense: builder.query({
      query: ({ companyId, startDate, endDate }) => ({
        url: `/get-company-vehicles-audit?companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`,
        method: "GET",
      }),
    }),
    getCompanyHomePageData: builder.query({
      query: ({ companyId, date, period }) => {
        let url = `/get-company-data?companyId=${companyId}`;
        if (date) url += `&date=${date}`;
        if (period) url += `&period=${period}`;
        return {
          url,
          method: "GET",
        };
      },
    }),
    getCompanyTripsAndShares: builder.query({
      query: ({ companyId, date, period,page }) => {
        let url = `/count-trips?companyId=${companyId}&page=${page}`;
        if (date) url += `&date=${date}`;
        if (period) url += `&period=${period}`;
        return {
          url,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetCompanyTripsAndSharesQuery,
  useGetCompanyHomePageDataQuery,
  usePostUserSignUpMutation,
  usePostCompanyAddVehicleMutation,
  usePostUserLoginMutation,
  useGetCompanyFleetQuery,
  usePostCompanyAddParcelMutation,
  useGetCompanyParcelsQuery,
  usePostTicketDataMutation,
  useGetSoldTicketsAndDataQuery,
  usePostCompanyAddOfficeMutation,
  useGetCompanyOfficesQuery,
  usePostCompanyAddRouteMutation,
  useGetCompanyRoutesAndFareQuery,
  usePostCompanyFareRatesMutation,
  useGetCompanyFilteredTicketSalesQuery,
  usePostSpecificUserSignUpMutation,
  usePostNormalUserLoginMutation,
  useLazyGenerateOfficePasswordQuery,
  useGetCompanyAuditTotalIncomeQuery,
  useLazyGetCompanyAuditTotalIncomeQuery,
  useLazyGetCompanyAuditTotalExpenseQuery,
} = busLineApi;
export const { getCompanyFleet, getCompanyOffices } = busLineApi.endpoints;
