import React, { useEffect, useState } from "react";
import { createColumns, ScheduleListItems } from "./schedule_list_column";
import { DataTable } from "../Re-Usables/data-table";
import { useGetScheduleListQuery } from "../../../api/busLineOfficeApi";
import { errorHandler } from "../../../error/index";
import { Loader2 } from "lucide-react";
import { useOfficeUserAuth } from "../../../api/officeAuth";

interface TableData {
  time: string;
  vehicles: Vehicle[];
  route: Route[];
}

interface Vehicle {
  bookedSeats: string[];
  registrationNumber: string;
  driver: Driver;
}
interface Driver {
  firstName: string;
  lastName: string;
}
interface Route {
  name: string;
}

export default function ScheduleListPage() {
  const { officeId } = useOfficeUserAuth();
  const [pageIndex, setPageIndex] = useState(0);
  const { isLoading, data, error, isError } = useGetScheduleListQuery({
    officeId,
  });
  useEffect(() => {
    if (isError) {
      errorHandler(error);
    }
  }, [error, isError]);

  const prepareTableData = (): ScheduleListItems[] => {
    if (!data || !data.data) {
      return [];
    }

    return data.data.flatMap((scheduleItem: TableData) =>
      scheduleItem.vehicles.map((vehicle: Vehicle) => ({
        vehicle: vehicle.registrationNumber,
        driver: `${vehicle.driver.firstName} ${vehicle.driver.lastName}`,
        departureTime: scheduleItem.time,
        route: scheduleItem.route[0]?.name || "No route specified.",
        occupiedSeats: vehicle.bookedSeats.length===0?'None booked.':vehicle.bookedSeats.map((seat) => seat)
      }))
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  const tableData = prepareTableData();

  return (
    <>
      <DataTable
        columns={createColumns()}
        data={tableData}
        pageCount={1}
        pageIndex={pageIndex}
        onPaginationChange={setPageIndex}
      />
    </>
  );
}
