import React, { useState, useEffect } from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from "./../../components/ui/dialog";
import { Input } from "./../../components/ui/input";
import { formatDateShortHand } from "../utility_components/utility_functions/utilityFunctions";
import { useGetActiveScheduleTimesQuery } from "../../api/busLineOfficeApi";
import { errorHandler } from "../../error/index";
import { useOfficeUserAuth } from "../../api/officeAuth";
import { Loader2 } from "lucide-react";

interface Time {
  time: string;
}

interface SelectedTimeProps{
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setTimeSelected:(value:string) =>void
}

export default function SelectTimeToTravel({ isOpen, setIsOpen,setTimeSelected }:SelectedTimeProps) {
  const [today] = useState<Date>(new Date());
  const { officeId } = useOfficeUserAuth();
  const { isLoading, data, error, isError } = useGetActiveScheduleTimesQuery({
    officeId,
  });
  const [selectedTime, setSelectedTime] = useState<string | null>(null);

  const handleConfirm = () => {
    setIsOpen(false);
    setTimeSelected(selectedTime);
    // Do something with the selected time
    console.log("Selected time:", selectedTime);
  };

  const handleTimeClick = (time: string) => {
    setSelectedTime(time);
  };
  useEffect(() => {
    if (isError) {
      errorHandler(error);
    }
  }, [error, isError]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Select Time</DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <Input
            placeholder="Search vehicles..."
            value={formatDateShortHand(today)}
            className="flex-1 border-2 border-blue-500 text-center font-bold focus:outline-none focus:border-blue-500 focus:ring-0 focus-visible:ring-0 text-xl"
            readOnly
          />
        </div>

        <div className="grid grid-cols-3 gap-4">
          {data?.data.length === 0 ? (
            <p className="text-center text-gray-300">
              There are no vehicles scheduled. Try later.
            </p>
          ) : (
            data?.data.map((item: Time, index: number) => (
              <button
                key={index}
                className={`bg-customDarkBlue text-white py-3 px-6 rounded-md cursor-pointer transition-colors duration-300 ${
                  selectedTime === item.time
                    ? "bg-customDarkBlue/80"
                    : "hover:bg-customDarkBlue/80"
                }`}
                onClick={() => handleTimeClick(item.time)}
              >
                {item.time}
              </button>
            ))
          )}
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">Cancel</Button>
          </DialogClose>
          <Button
            onClick={handleConfirm}
            className="bg-customDarkBlue"
            variant="outline"
            disabled={selectedTime === null}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
