import React from "react";
import { CalendarCheck2 } from "lucide-react";
import ScheduleListPage from "../business_components/Schedule-List/schedule_list_page";

export default function ScheduleList() {
  return (
    <>
      <div className="container mx-auto">
        <div className="flex justify-start mb-2">
          <CalendarCheck2
            size={50}
            color="blue"
            onClick={() => console.log("You clicked a calendar")}
          />
        </div>

        <div className="py-0">
        <ScheduleListPage />
        </div>
      </div>
    </>
  );
}
