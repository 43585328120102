import React, { useState } from "react";
import { Card, CardContent, CardTitle } from "../../components/ui/card";
import { AddTimePickerDialog } from "./AddButtonTimePicker";
import { cn } from "../../lib/utils";
import VehiclePerSchedule from "../business_components/VehiclesPerSchedule/VehiclePerSchedulePage";

interface VehicleData {
  id: string;
  registrationNumber: string;
  model: string;
  capacity: number;
}
interface ScheduleData {
  scheduleId: string;
  time: string;
  routeNames: string[];
  vehicleCount: number;
  totalCapacity: number;
  totalBookedSeats: number;
  availableSeats: number;
}

interface ScheduleCardProps {
  routeNames: string[];
  vehicleData: VehicleData[];
  scheduleData: ScheduleData;
  officeId: string;
}

const ScheduleCard = ({
  scheduleData,
  vehicleData,
  routeNames,
  officeId,
}: ScheduleCardProps) => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isVehicleScheduleOpen, setIsVehicleScheduleOpen] = useState(false);

  return (
    <Card
      className={cn(
        "border-green-700 p-4 flex flex-col justify-between h-full"
      )}
    >
      <CardTitle className="text-center mb-4 font-semibold text-lg text-green-700">
        {scheduleData.time}
      </CardTitle>
      <CardContent className="space-y-2">
        <p className="text-start">VEHICLES: {scheduleData.vehicleCount}</p>
        <p className="text-start">EMPTY SEATS: {scheduleData.availableSeats}</p>
      </CardContent>
      <div className="flex mt-4">
        <button
          onClick={() => setIsVehicleScheduleOpen(true)}
          className="self-end bg-green-700 text-white px-4 py-2 rounded hover:bg-green-800 transition duration-200 mr-10"
        >
          VIEW
        </button>
        <button
          onClick={() => setIsAddDialogOpen(true)}
          className="self-end bg-green-700 text-white px-4 py-2 rounded hover:bg-green-800 transition duration-200"
        >
          ADD
        </button>
      </div>
      {isVehicleScheduleOpen && (
        <VehiclePerSchedule
          isOpen={isVehicleScheduleOpen}
          officeId={officeId}
          scheduleId={scheduleData.scheduleId}
          setOpen={setIsVehicleScheduleOpen}
        />
      )}

      <AddTimePickerDialog
        isOpen={isAddDialogOpen}
        setOpen={setIsAddDialogOpen}
        vehicleData={vehicleData}
        selectedTime={scheduleData.time}
        routeNames={routeNames}
        selectedScheduleId={scheduleData.scheduleId}
      />
    </Card>
  );
};

export default ScheduleCard;
