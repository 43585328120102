import React,{useState, useEffect} from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Label } from "../../components/ui/label";

const TimePicker = ({ onChange }) => {
    const [hour, setHour] = useState("12");
    const [minute, setMinute] =useState("00");
    const [period, setPeriod] = useState("AM");
  
    const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
    const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
  
    useEffect(() => {
      const timeString = `${hour}:${minute}${period}`;
      onChange?.(timeString);
    }, [hour, minute, period, onChange]);
  
    return (
      <div className="flex flex-col space-y-2">
        <Label className="text-green-900">Add Departure Time</Label>
        <div className="flex space-x-2">
          <Select value={hour} onValueChange={setHour}>
            <SelectTrigger className="w-20">
              <SelectValue placeholder="Hour" />
            </SelectTrigger>
            <SelectContent>
              {hours.map((h) => (
                <SelectItem key={h} value={h}>
                  {h}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
  
          <Select value={minute} onValueChange={setMinute}>
            <SelectTrigger className="w-20">
              <SelectValue placeholder="Minute" />
            </SelectTrigger>
            <SelectContent>
              {minutes.map((m) => (
                <SelectItem key={m} value={m}>
                  {m}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
  
          <Select value={period} onValueChange={setPeriod}>
            <SelectTrigger className="w-20">
              <SelectValue placeholder="AM/PM" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="AM">AM</SelectItem>
              <SelectItem value="PM">PM</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    );
};
  
export { TimePicker };