import { configureStore } from "@reduxjs/toolkit";
import { busLineApi } from "../api/busLineApiData";
import errorReducer from "../slices/errorSlice";
import successReducer from "../slices/successSlice";
import loaderReducer from "../slices/loader";
import authReducer from "../slices/authSlice";
import warningReducer from "../slices/showWarnings";
import userAuthReducer from "../slices/userSlice";
import { busLineUserApi } from "../api/busLineUserApi";
import toastSuccess from "../slices/toastSuccess";
import { busLineOfficeApi } from "../api/busLineOfficeApi";
import companyAuthSlice from "../slices/companyAuth";
import OwnerAuthSlice from "../slices/setOwnerAuth";
import vehicleIdSlice from "../slices/vehicleId";
import refetchSlice from '../slices/refetchSlice';
import driverRefetch from '../slices/driverRefetch';
import scheduleSlice from "../slices/schedule";
import updateVehicleData from "../slices/addTimeRefetch";
import addVehicleRefetch from "../slices/addVehicleToSchedule";

export const store = configureStore({
  reducer: {
    [busLineApi.reducerPath]: busLineApi.reducer,
    [busLineUserApi.reducerPath]: busLineUserApi.reducer,
    [busLineOfficeApi.reducerPath]: busLineOfficeApi.reducer,
    error: errorReducer,
    success: successReducer,
    loader: loaderReducer,
    auth: authReducer,
    warning: warningReducer,
    userAuth: userAuthReducer,
    toastSuccess: toastSuccess,
    companyAuth: companyAuthSlice,
    ownerAuth: OwnerAuthSlice,
    vehicleId: vehicleIdSlice,
    refetchTicketsPage: refetchSlice,
    driverRefetch: driverRefetch,
    refetchSchedulePage: scheduleSlice,
    updateVehicleDataRefetch: updateVehicleData,
    refetch:addVehicleRefetch
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(busLineApi.middleware)
      .concat(busLineUserApi.middleware)
      .concat(busLineOfficeApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
